import React from "react";

const Logo = () => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.9463 39.9463C38.6179 41.2747 36.9565 42.0635 35.2328 42.3181C34.0487 42.4926 32.8342 42.4143 31.6786 42.0831C30.8381 41.8427 30.0297 41.4688 29.2818 40.9613C28.8491 40.6674 28.4377 40.3291 28.0549 39.9463C27.672 39.5634 27.3337 39.1521 27.0399 38.7194C26.4897 37.9092 26.0961 37.0277 25.8593 36.1107C25.578 35.0173 25.5174 33.8813 25.683 32.7683C25.9359 31.0429 26.7247 29.3815 28.0531 28.0531C29.3815 26.7247 31.0429 25.9359 32.7683 25.683C33.8795 25.5174 35.0173 25.578 36.1107 25.8593C37.0277 26.0961 37.9092 26.4897 38.7194 27.0399C39.1521 27.3337 39.5634 27.672 39.9463 28.0549C40.3291 28.4377 40.6674 28.8491 40.9613 29.2818C41.4688 30.0279 41.8427 30.8381 42.0831 31.6786C42.4161 32.8342 42.4944 34.0487 42.3181 35.2328C42.0635 36.9583 41.2747 38.6179 39.9463 39.9463Z"
      fill="white"
    />
    <path
      d="M58.0395 58.0399C46.3956 69.6838 28.4107 71.1154 15.207 62.3402C14.4573 61.8434 13.7236 61.3128 13.0078 60.7483L21.6423 52.1138C22.4116 52.6391 23.2058 53.1092 24.0195 53.5259C32.2588 57.7372 42.6027 56.3945 49.4993 49.4997C56.3959 42.6049 57.7385 32.2592 53.5254 24.02L47.4961 30.0493C47.2272 29.1234 46.8621 28.2206 46.3974 27.3587C46.0038 26.6198 45.5373 25.9093 44.9995 25.2379C44.6737 24.8284 44.3211 24.4331 43.9418 24.0538C43.5625 23.6745 43.1672 23.3219 42.7577 22.9961L56.8909 8.86279C57.2791 9.21715 57.662 9.58219 58.0377 9.95613C58.4134 10.3301 58.7785 10.7147 59.131 11.1029C59.6955 11.7208 60.2333 12.3565 60.7461 13.0064C61.3106 13.7223 61.8394 14.4559 62.338 15.2056C71.115 28.4093 69.6816 46.3942 58.0377 58.0381L58.0395 58.0399Z"
      fill="white"
    />
    <path
      d="M25.2391 45.0017L11.1058 59.1349C10.7176 58.7806 10.3348 58.4156 9.95907 58.0416C9.58334 57.6677 9.2183 57.283 8.86573 56.8948C8.25674 56.2271 7.67801 55.5415 7.13135 54.8382C6.61317 54.1704 6.1217 53.4884 5.65872 52.7922C-3.11826 39.5866 -1.68481 21.6017 9.95729 9.95785C21.5994 -1.68603 39.5861 -3.1177 52.7916 5.65929C53.4878 6.12226 54.1698 6.61195 54.8376 7.13191L46.1942 15.7753C45.4766 15.2946 44.7376 14.8601 43.979 14.4719C35.7398 10.2606 25.394 11.6014 18.4974 18.498C11.6009 25.3946 10.26 35.7403 14.4713 43.9796L20.4989 37.952C20.75 38.8138 21.0865 39.6543 21.505 40.4645C21.9199 41.2658 22.4185 42.0368 22.9972 42.7616C23.3231 43.1711 23.6757 43.5664 24.0549 43.9457C24.4342 44.325 24.8295 44.6776 25.2391 45.0034V45.0017Z"
      fill="white"
    />
  </svg>
);

export default Logo;

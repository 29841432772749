import React from "react";
import Logo from "./icons/Logo";

function App() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-primary text-white">
      <div className="flex items-center">
        <span className="gradient-text">
          <Logo />
        </span>
        <h1 className="text-7xl font-bold ml-4 gradient-text leading-tight">
          Coming Soon
        </h1>
      </div>
      <footer className="absolute bottom-0 w-full text-center py-4 bg-secondary">
        <p>&copy; {new Date().getFullYear()} Glowy. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
